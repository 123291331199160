import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styled, { css } from 'styled-components'

export default ({ video }) => (

  <VideoColumn>

    <VideoColumnInner to={`/video/${video.slug}`}>

      <VideoThumbnail>
          { video.thumbnail ?
          <Img fluid={video.thumbnail.fluid} imgStyle={{ objectFit: "cover"}} alt={video.title}/>
          :
          ''
          }
      </VideoThumbnail>

      <VideoTitle>
        {video.title}
      </VideoTitle>

      <VideoDescription
        dangerouslySetInnerHTML={{
          __html: video.description,
        }}
      />

    </VideoColumnInner>

  </VideoColumn>
)

const VideoColumn = styled.div `
  width: calc(33.333% - 16px);
  margin-right: 24px;
  display: flex;
  margin-bottom 24px;
  transition: all 240ms ease-in-out;
  align-items: flex-start;

  &:nth-child(3n) {
    margin-right: 0;
  }

  @media (max-width: 768px) {
    width: calc(50% - 12px);

    &:nth-child(2n) {
      margin-right: 0;
    }

    &:nth-child(3n) {
      margin-right: 24px;
    }
  }

  @media (max-width: 540px) {
    width: 100%;
    margin-right: 0;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }
`

const VideoColumnInner = styled(props => <Link {...props} />)`
  width: 100%;
  text-decoration: none;
`

const VideoThumbnail = styled.div `
  margin-top: auto;
  max-width: 100%;
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 240ms ease-in-out;

  .gatsby-image-wrapper, img, picture, picture img {
    height: 272px;
    width: 100%;
    object-fit: contain;
    border-radius: 8px;
  }
`

const NormalImage = styled.img`
  height: auto;
  max-width: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
  position: relative;
  z-index: 2;
`

const VideoTitle = styled.h3`
  font-size: 24px;
  line-height: 1.5;
  margin-bottom: 12px;
  margin-top: 24px;
  display: block;
  width: 100%;
`

const VideoDescription = styled.p `
  color: #9C9C9C;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 0;
  font-weight: 400;
  width: 100%;
`
