import React from 'react'
import Img from 'gatsby-image'
import styled, { css } from 'styled-components'
import MediumImage from 'gatsby-image'
import EmailSignup from '../../components/email-signup/email-signup'

// heroHeading
// heroParagraph
// heroImage

export default ({ data, heroHeading, heroParagraph, heroImage, children, classes }) => (
  <div>

    <ImageContainer className={ "container boxed-width align-items-center " + ( classes || "") }>

      <TextColumn className="col-6 col-12-t">
        <HeroTextContainer>
          <HeroHeading>{heroHeading}</HeroHeading>
          <p>{heroParagraph}</p>
          <div className="container">{children}</div>
        </HeroTextContainer>
      </TextColumn>

      <PanelContainer className="col-5 col-12-t">
        { heroImage ?
        <NormalImage src={heroImage} />
        :
        ''
        }
      </PanelContainer>

    </ImageContainer>

  </div>
)

// Styled components
const ImageContainer = styled.div`
  padding-top: 120px;
  padding-bottom: 144px;

  &.no-padding-bottom {
    padding-bottom: 0;
  }
`

const NormalImage = styled.img`
  height: auto;
  max-width: 282px;
  object-fit: contain;
  object-position: center;
  position: relative;
  z-index: 2;
  border-radius: 10px;
`
const TextColumn = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
`

const HeroTextContainer = styled.div`
  text-align: left;
`

const HeroEmojiSpan = styled.span`
  padding: 0 0px;
`

const PanelContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: flex-end;

  @media (max-width: 768px) {
    justify-content: center;
  }
`

const HeroHeading = styled.h1`
  line-height: 1.25;
  font-size: 36px;`