import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import styled, { css } from 'styled-components'

//Components
import Hero from '../components/heros/standard-hero'
import Layout from '../components/layout'
import Navigation from '../components/navigation'
import VideoPreview from '../components/teasers/video-preview'
import EmailCtaSection from '../components/email-signup/email-cta-section'

class VideoIndex extends React.Component {
  render() {
    const { data } = this.props
    const { currentPage, numVideos } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numVideos
    const prevPage = currentPage - 1 === 1 ? '/' : (currentPage - 1).toString()
    const nextPage = (currentPage + 1).toString()
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const videos = get(this, 'props.data.allContentfulVideo.edges')

    return (
      <div>
        <Navigation />
        <BlueBackground>
          <Layout location={this.props.location} >
            <Helmet title={siteTitle} />

            <Hero 
            heroHeading="All of the videos."
            heroParagraph="Look below and you will find all of the videos available throughout the site."
            />

            <div className="wrapper container boxed-width">
              <ComponentHeader>
                <h3>There are {videos.length} videos available to read</h3>
              </ComponentHeader>

              <div className="container section-padding-bottom">
                <div className="container">
                  <BlogList className="article-list container justify-content-start">
                      {videos.map(({ node }) => {
                          return (
                            <VideoPreview video={node} key={node.slug}/>
                          )
                      })}
                  </BlogList>
                </div>

                <PaginationContainer>

                  {Array.from({ length: numVideos }, (_, i) => (

                    <PaginationItem key={`pagination-number${i + 1}`} activeClassName="is-current-page" to={`/videos/${i === 0 ? "" : i + 1}`}>
                      {i + 1}
                    </PaginationItem>

                  ))}

                </PaginationContainer>

              </div>
            </div>

            <EmailCtaSection />


          </Layout>
        </BlueBackground>
      </div>
    )
  }
}

export default VideoIndex

export const pageQuery = graphql`
  query videosQuery($skip: Int!, $limit: Int!, $sort: [SortOrderEnum]!) {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulVideo(sort: { fields: [publishDate], order: $sort }
      limit: $limit
      skip: $skip
      )
      {
      edges {
        node {
          title
          slug
          thumbnail {
            fluid(quality: 100) {
              src
              tracedSVG
              aspectRatio
              srcSet
              sizes
            }
          }
          description
        }
      }
    }
  }
`

// Styles

const BlueBackground = styled.div `
  background-color: #1a1a1a;
`

const BlogList = styled.div`
  list-style: none;
  margin-bottom: -24px;
`

const HeroEmojiSpan = styled.span`
  padding: 0 0px;
`

const TopPageHighlight = styled.img `
  position: absolute;
  top: 0;
  right: 0;
  max-width: 50%;
  z-index: 0;
`

const ComponentHeader = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
`

const PaginationContainer = styled.div `
  padding-top: 48px;
  display: flex;
  justify-content: center;
  width: 100%;
`

const PaginationItem = styled(props => <Link {...props} />) `
  width: 48px;
  height: 48px;
  border-radius: 8px;
  border: solid 2px #FFD167;
  color: #FFD167;
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;

  &:hover {
    background-color: #FFD167;
    color: #1a1a1a;
  }

  &:last-child {
    margin-right: 0;
  }

  &.is-current-page {
    background-color: #FFD167;
    color: #1a1a1a;
  }
`